type FinancingChoice = 'finance' | 'lease';
type VehicleChoice = 'vin' | 'ymm';
type VehicleType = 'new' | 'used' | 'cpo';

interface AprRatesDetails {
    rates: ChapmanConnectAprRates['rates'];
    defaults: ChapmanConnectAprRates['defaults'];
    customer: {
        term: number | null;
        creditTier: string | null;
    };
}

export interface SelectableChapmanConnectEnhancementItem
    extends ChapmanConnectEnhancementItem {
    selected: boolean;
}

export const useChapmanConnectStore = defineStore('chapman-connect', () => {
    const kbbStore = useKbbStore();
    const appConfig = useAppConfig();
    const chapmanConnectApi = new ChapmanConnectApi();
    const paymentCalculatorApi = new PaymentCalculatorApi();

    const status = ref<{ new: Status; used: Status; cpo: Status; tax: Status }>(
        { new: 'initial', used: 'initial', cpo: 'initial', tax: 'initial' }
    );
    const aprDetails = ref<{
        new: AprRatesDetails;
        used: AprRatesDetails;
        cpo: AprRatesDetails;
        downPayment: number | null;
        customApr: number;
        customTerm: number;
    }>({
        new: {
            rates: {},
            defaults: {
                term: null,
                creditTier: null,
                downPaymentType: null,
                downPayment: null,
            },
            customer: { term: null, creditTier: null },
        },
        used: {
            rates: {},
            defaults: {
                term: null,
                creditTier: null,
                downPaymentType: null,
                downPayment: null,
            },
            customer: { term: null, creditTier: null },
        },
        cpo: {
            rates: {},
            defaults: {
                term: null,
                creditTier: null,
                downPaymentType: null,
                downPayment: null,
            },
            customer: { term: null, creditTier: null },
        },
        downPayment: null,
        customApr: 0,
        customTerm: 0,
    });
    const enhancements = ref<{
        new: SelectableChapmanConnectEnhancementItem[];
        used: SelectableChapmanConnectEnhancementItem[];
        cpo: SelectableChapmanConnectEnhancementItem[];
    }>({ new: [], used: [], cpo: [] });
    const fees = ref<{
        new: ChapmanConnectFeeItem[];
        used: ChapmanConnectFeeItem[];
        cpo: ChapmanConnectFeeItem[];
    }>({ new: [], used: [], cpo: [] });
    const taxRate = ref(0);
    const installedOptions = ref<{
        new: SelectableChapmanConnectEnhancementItem[];
        used: SelectableChapmanConnectEnhancementItem[];
        cpo: SelectableChapmanConnectEnhancementItem[];
    }>({ new: [], used: [], cpo: [] });

    // Payment Step
    const financingChoices = ref<{ label: string; value: FinancingChoice }[]>([
        { label: 'Finance', value: 'finance' },
        { label: 'Lease', value: 'lease' },
    ]);
    const financingChoice = ref<FinancingChoice>('finance');
    const useCustomRate = ref(false);

    // Payment Step - Lease
    const mileageOptions = ref<{ label: string; value: number }[]>([
        { label: '10,000', value: 10000 },
        { label: '12,000', value: 12000 },
        { label: '15,000', value: 15000 },
        { label: '16,000', value: 16000 },
        { label: '17,000', value: 17000 },
        { label: '18,000', value: 18000 },
        { label: '19,000', value: 19000 },
        { label: '20,000', value: 20000 },
    ]);
    const mileage = ref(15000);
    const leaseCreditScore = ref(660);
    const leaseDownPayment = ref(2000);
    const taxLocations = ref<PaymentCalculatorTaxLocation[]>([]);
    const leaseSelectedOption =
        ref<PaymentCalculatorProgramQuotesResponseQuote | null>(null);

    // Value Trade Step
    const findVehicleChoices = ref<{ label: string; value: VehicleChoice }[]>([
        { label: 'VIN', value: 'vin' },
        { label: 'Model', value: 'ymm' },
    ]);
    const findVehicleChoice = ref<VehicleChoice>('vin');
    const tradeInValue = ref(0);

    // Customer Data
    const customerDataKey = ref('cag-vdp-customer-info');
    const customerData = ref<{
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        zipCode: string;
    }>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        zipCode: '',
    });
    const showCustomerModal = ref(false);
    const customerCallback = ref<(() => void) | null>(null);

    // Vehicle Data
    const currentLeadArkona = ref('');
    const vehicleVin = ref('');
    const vehicleMileage = ref(0);
    const vehicleType = ref<VehicleType>('new');
    const vehicleYear = ref<number | null>(null);
    const vehicleMake = ref<string | null>(null);
    const vehicleModel = ref<string | null>(null);
    const vehiclePricing = useVehiclePricing({});

    const discountedPriceValue = computed(() => {
        return vehiclePricing.discountedPriceValue.value ?? 0;
    });

    const loanAmount = computed(() => {
        if (
            hasLeaseSelectedOption.value &&
            leaseSelectedOption.value &&
            leaseSelectedOption.value.payment &&
            leaseSelectedOption.value.payment.totalOfPayments
        ) {
            return leaseSelectedOption.value.payment.totalOfPayments;
        }

        let amount = discountedPriceValue.value;

        amount += totalEnhancements.value;
        amount += totalInstalledOptions.value;
        amount -= tradeInValue.value;
        amount -= currentDownPayment.value;
        amount += payoffAmount.value;
        amount += groupedFees.value.total;
        amount += totalTaxes.value;

        return amount;
    });

    const monthlyPayment = computed(() => {
        if (
            hasLeaseSelectedOption.value &&
            leaseSelectedOption.value &&
            leaseSelectedOption.value.payment &&
            leaseSelectedOption.value.payment.paymentAmount
        ) {
            return Math.round(leaseSelectedOption.value.payment.paymentAmount);
        }

        return rawMonthlyPayment(
            loanAmount.value,
            apr.value,
            currentTermLength.value
        );
    });

    // Financing
    const currentAprDetails = computed(() => {
        return aprDetails.value[vehicleType.value];
    });
    const termLengths = computed(() => {
        return scoreToTermMap.value[currentCreditScore.value] ?? [];
    });
    const allTermLengths = computed(() => {
        const allTermLengths = new Set<number>();

        for (const term of Object.keys(aprDetails.value.new.rates)) {
            allTermLengths.add(parseInt(term));
        }

        for (const term of Object.keys(aprDetails.value.used.rates)) {
            allTermLengths.add(parseInt(term));
        }

        for (const term of Object.keys(aprDetails.value.cpo.rates)) {
            allTermLengths.add(parseInt(term));
        }

        return Array.from(allTermLengths);
    });
    const foundAprRate = computed(() => {
        return currentAprDetails.value.rates?.[currentTermLength.value]?.[
            currentCreditScore.value
        ];
    });
    const apr = computed(() => {
        if (useCustomRate.value) {
            return aprDetails.value.customApr;
        }

        return foundAprRate.value ?? 0;
    });
    const scoreToTermMap = computed(() => {
        const rateEntries = Object.entries(
            aprDetails.value[vehicleType.value].rates
        );

        const scoreToTermMap: {
            [key: string]: number[] | undefined;
        } = {};

        for (const [term, scores] of rateEntries) {
            for (const [score, rate] of Object.entries(scores)) {
                if (rate) {
                    if (score in scoreToTermMap) {
                        scoreToTermMap[score]?.push(parseInt(term));
                    } else {
                        scoreToTermMap[score] = [parseInt(term)];
                    }
                }
            }
        }

        return scoreToTermMap;
    });
    const creditScores = computed(() => {
        const rateValues = Object.values(
            aprDetails.value[vehicleType.value].rates
        );

        const set = new Set<string>();

        for (const scoresObject of rateValues) {
            for (const [score, rate] of Object.entries(scoresObject)) {
                if (!rate) break;
                set.add(score);
            }
        }

        return Array.from(set).sort();
    });
    const startingCreditScore = computed(() => {
        if (creditScores.value.length === 0) return '';

        if (
            currentAprDetails.value.defaults?.creditTier &&
            creditScores.value.includes(
                currentAprDetails.value.defaults.creditTier
            )
        ) {
            return currentAprDetails.value.defaults.creditTier;
        } else {
            return creditScores.value[creditScores.value.length - 1];
        }
    });
    const startingTerm = computed(() => {
        const terms = scoreToTermMap.value[startingCreditScore.value];

        if (!terms) return 0;

        if (
            currentAprDetails.value.defaults?.term &&
            terms.includes(currentAprDetails.value.defaults.term)
        ) {
            return currentAprDetails.value.defaults.term;
        } else {
            return terms[terms.length - 1];
        }
    });
    const startingDownPayment = computed(() => {
        if (
            currentAprDetails.value.defaults?.downPayment &&
            currentAprDetails.value.defaults.downPaymentType === 'fixed'
        ) {
            return discountedPriceValue.value <
                currentAprDetails.value.defaults.downPayment
                ? discountedPriceValue.value
                : currentAprDetails.value.defaults.downPayment;
        } else if (
            currentAprDetails.value.defaults?.downPayment &&
            currentAprDetails.value.defaults.downPaymentType === 'percentage'
        ) {
            // Round down to nearest hundred.
            const rawDownPayment =
                discountedPriceValue.value *
                currentAprDetails.value.defaults.downPayment;
            return Math.floor(rawDownPayment / 100) * 100;
        } else {
            return discountedPriceValue.value < 10000 ? 0 : 2000;
        }
    });
    const maxDownPayment = computed(() => {
        const value =
            Math.floor(
                (discountedPriceValue.value - 1000 - tradeInValue.value) / 100
            ) * 100;

        return value >= 0 ? value : 0;
    });
    const currentCreditScore = computed(() => {
        const customerCreditTier = currentAprDetails.value.customer.creditTier;

        if (
            customerCreditTier &&
            creditScores.value.includes(customerCreditTier)
        ) {
            return customerCreditTier;
        } else {
            return startingCreditScore.value;
        }
    });
    const currentTermLength = computed(() => {
        if (useCustomRate.value) {
            return aprDetails.value.customTerm;
        }

        const customerTerm = currentAprDetails.value.customer.term;

        if (customerTerm && termLengths.value.includes(customerTerm)) {
            return customerTerm;
        } else {
            return startingTerm.value;
        }
    });
    const currentDownPayment = computed(() => {
        return Math.min(
            maxDownPayment.value,
            aprDetails.value.downPayment ?? startingDownPayment.value
        );
    });

    // Enhancements
    const sortedEnhancements = computed(() => {
        const copy = [...enhancements.value[vehicleType.value]];
        return copy.sort((enhancement) =>
            enhancement.isUserSelectable ? -1 : 1
        );
    });
    const selectedEnhancements = computed(() => {
        return sortedEnhancements.value.filter(
            (enhancement) => enhancement.selected
        );
    });
    const totalEnhancements = computed(() => {
        return selectedEnhancements.value.reduce((acc, curr) => {
            return acc + curr.price;
        }, 0);
    });
    const totalTaxableEnhancements = computed(() => {
        return selectedEnhancements.value.reduce((acc, curr) => {
            if (curr.isTaxable) {
                return acc + curr.price;
            }
            return acc;
        }, 0);
    });

    // Dealer Installed Options
    const totalInstalledOptions = computed(() => {
        return installedOptions.value[vehicleType.value].reduce((acc, curr) => {
            return acc + curr.price;
        }, 0);
    });
    const totalTaxableInstalledOptions = computed(() => {
        return installedOptions.value[vehicleType.value].reduce((acc, curr) => {
            if (curr.isTaxable) {
                return acc + curr.price;
            }
            return acc;
        }, 0);
    });

    // Fees
    const groupedFees = computed(() => {
        interface GroupedFees {
            total: number;
            groups: {
                [key: string]: {
                    fees: ChapmanConnectFeeItem[];
                    total: number;
                };
            };
        }
        const info: GroupedFees = {
            total: 0,
            groups: {},
        };

        fees.value[vehicleType.value].forEach((fee) => {
            // Determine the value of this fee, based on fixed or not
            const feeValue = fee.isFixed
                ? fee.value
                : calculateFeeByRate(discountedPriceValue.value, fee.value);

            const name = fee.groupName || fee.friendlyName;

            // Add value to total for whole group
            info.total += feeValue;

            if (name) {
                // If fee group exists, add to total and fees array
                if (info.groups[name]) {
                    info.groups[name].total += feeValue;
                    info.groups[name].fees.push(fee);
                }

                // Else create group for first time
                else {
                    info.groups[name] = {
                        total: feeValue,
                        fees: [fee],
                    };
                }
            }
        });

        info.total = Math.floor(info.total);

        return info;
    });
    const getGroupedFeesTotalByName = computed(() => {
        return (name: string) => {
            return groupedFees.value.groups[name]?.total ?? 0;
        };
    });
    const ungroupedFees = computed(() => {
        interface UngroupedFees {
            total: number;
            fees: {
                [key: string]: {
                    fee: ChapmanConnectFeeItem;
                    total: number;
                };
            };
        }

        const info: UngroupedFees = {
            total: 0,
            fees: {},
        };

        fees.value[vehicleType.value].forEach((fee) => {
            if (!fee.groupName) {
                // Determine the value of this fee, based on fixed or not
                const feeValue = fee.isFixed
                    ? fee.value
                    : calculateFeeByRate(discountedPriceValue.value, fee.value);

                // Add value to total for whole group
                info.total += feeValue;

                // Add fee to fees array
                info.fees[fee.friendlyName] = {
                    total: feeValue,
                    fee,
                };
            }
        });

        info.total = Math.floor(info.total);

        return info;
    });
    const totalTaxableFees = computed(() => {
        return fees.value[vehicleType.value].reduce((total, fee) => {
            if (fee.isTaxable) {
                const feeValue = fee.isFixed
                    ? fee.value
                    : calculateFeeByRate(discountedPriceValue.value, fee.value);
                return total + feeValue;
            }
            return total;
        }, 0);
    });

    const taxBasis = computed(() => {
        let totalTaxables = discountedPriceValue.value;
        totalTaxables += totalTaxableEnhancements.value;
        totalTaxables += totalTaxableInstalledOptions.value;
        totalTaxables += totalTaxableFees.value;
        totalTaxables -= tradeInValue.value;

        const rawBasis = Math.floor(totalTaxables);

        return rawBasis < 0 ? 0 : rawBasis;
    });

    const totalTaxes = computed(() => {
        const taxSummary = leaseSelectedOption.value?.taxSummary;

        if (taxSummary && taxSummary.totalTax) {
            // When a lease option is selected
            return Math.floor(taxSummary.totalTax);
        } else {
            // When not using leasing
            const rate = taxRate.value || 0.086;
            return Math.floor(taxBasis.value * rate);
        }
    });

    const hasCustomerData = computed(() => {
        const hasSavedCustomerData =
            customerData.value.firstName &&
            customerData.value.lastName &&
            customerData.value.email &&
            customerData.value.zipCode;

        return !!hasSavedCustomerData;
    });

    const hasLeaseSelectedOption = computed(() => {
        return (
            financingChoice.value === 'lease' &&
            !!leaseSelectedOption.value &&
            !!Object.keys(leaseSelectedOption.value).length
        );
    });

    const combinedCreditScore = computed(() => {
        if (hasLeaseSelectedOption.value) {
            return leaseCreditScore.value.toString();
        } else {
            return currentCreditScore.value;
        }
    });

    const combinedDownPayment = computed(() => {
        if (hasLeaseSelectedOption.value && leaseSelectedOption.value) {
            return leaseSelectedOption.value.dueInAdvance.outOfPocket ?? 0;
        } else {
            return currentDownPayment.value;
        }
    });

    const combinedApr = computed(() => {
        if (
            hasLeaseSelectedOption.value &&
            leaseSelectedOption.value &&
            leaseSelectedOption.value.rate.apr
        ) {
            return Number(
                (leaseSelectedOption.value.rate.apr / 100).toPrecision(4)
            );
        } else {
            return apr.value;
        }
    });

    const combinedTermLength = computed(() => {
        if (hasLeaseSelectedOption.value && leaseSelectedOption.value) {
            return leaseSelectedOption.value.payment.term ?? 0;
        } else {
            return currentTermLength.value;
        }
    });

    const chapmanConnectLoading = computed(() => {
        return (
            status.value[vehicleType.value] === 'initial' ||
            status.value[vehicleType.value] === 'loading'
        );
    });

    const payoffAmount = computed(() => {
        return Math.floor(kbbStore.payoff.amount ?? 0);
    });

    const TOGGLE_ENHANCEMENT = (
        enhancement: SelectableChapmanConnectEnhancementItem
    ) => {
        const maybeEnhancement = enhancements.value[vehicleType.value].find(
            (x) => x.id === enhancement.id
        );

        if (maybeEnhancement) {
            maybeEnhancement.selected = !maybeEnhancement.selected;
        }
    };

    const TOGGLE_CUSTOM_RATE = () => {
        useCustomRate.value = !useCustomRate.value;
    };

    const GET_CUSTOMER_DATA = () => {
        let ls: string | null = null;

        try {
            ls = localStorage.getItem(customerDataKey.value);
        } catch (err) {
            handleLocalStorageError(err);
        }

        if (!ls) return;

        let data: Partial<{
            firstName: string;
            lastName: string;
            email: string;
            phone: string;
            zipCode: string;
        }> = {};

        try {
            data = JSON.parse(ls);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }

        customerData.value.firstName = data.firstName ?? '';
        customerData.value.lastName = data.lastName ?? '';
        customerData.value.email = data.email ?? '';
        customerData.value.phone = data.phone ?? '';
        customerData.value.zipCode = data.zipCode ?? '';
    };

    const SAVE_CUSTOMER_DATA = () => {
        try {
            localStorage.setItem(
                customerDataKey.value,
                JSON.stringify(customerData.value)
            );
        } catch (err) {
            handleLocalStorageError(err);
        }
    };

    const SET_VEHICLE = (
        car: InventoryDetailVehicle,
        pricingData: VehiclePricingParams
    ) => {
        // Set general vehicle information
        vehicleVin.value = car.vin;
        vehicleMileage.value = car.mileage;

        // Set vehicle type
        if (car.type === 'N') {
            vehicleType.value = 'new';
        } else if (car.certified) {
            vehicleType.value = 'cpo';
        } else {
            vehicleType.value = 'used';
        }

        vehicleYear.value = car.year;
        vehicleMake.value = car.make;
        vehicleModel.value = car.model;

        // Set vehicle pricing information
        vehiclePricing.updateValues(pricingData);
    };

    const GET_DATA = async (car: InventoryDetailVehicle) => {
        try {
            const leadArkona = getLeadArkona(
                car.uniqueArkona,
                car.type,
                appConfig.arkonas
            );

            status.value[vehicleType.value] = 'loading';

            const [
                ratesWithDefaults,
                enhancementsResponse,
                feesResponse,
                installedOptionsResponse,
                taxRateResponse,
            ] = await Promise.all([
                chapmanConnectApi.getFinanceRatesWithDefaultsV2(
                    leadArkona,
                    vehicleType.value,
                    vehicleYear.value ?? undefined,
                    vehicleMake.value,
                    vehicleModel.value
                ),
                chapmanConnectApi.getEnhancementsList(
                    leadArkona,
                    vehicleType.value
                ),
                chapmanConnectApi.getFeesList(leadArkona, vehicleType.value),
                chapmanConnectApi.getDealershipInstalledOptionsList(
                    leadArkona,
                    vehicleType.value
                ),
                chapmanConnectApi.getTaxRate(leadArkona),
            ]);

            aprDetails.value[vehicleType.value].rates = ratesWithDefaults.rates;
            aprDetails.value[vehicleType.value].defaults =
                ratesWithDefaults.defaults;

            enhancements.value[vehicleType.value] = enhancementsResponse.map(
                (enhancement) => {
                    return {
                        ...enhancement,
                        selected: !enhancement.isUserSelectable,
                    };
                }
            );
            fees.value[vehicleType.value] = feesResponse;
            installedOptions.value[vehicleType.value] =
                installedOptionsResponse.map((installedOption) => {
                    return {
                        ...installedOption,
                        selected: !installedOption.isUserSelectable,
                    };
                });
            taxRate.value = taxRateResponse;

            // Set the starting values for the custom inputs
            // Only set them once
            if (
                status.value.new !== 'loaded' &&
                status.value.used !== 'loaded' &&
                status.value.cpo !== 'loaded'
            ) {
                aprDetails.value.customTerm = startingTerm.value;
                aprDetails.value.customApr = foundAprRate.value ?? 0;
            }

            currentLeadArkona.value = leadArkona;
            status.value[vehicleType.value] = 'loaded';
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            status.value[vehicleType.value] = 'error';
        }
    };

    const GET_TAX_LOCATIONS = async (zipCode: string) => {
        if (status.value.tax === 'loading' || status.value.tax === 'loaded') {
            return;
        }

        try {
            status.value.tax = 'loading';

            taxLocations.value =
                await paymentCalculatorApi.getTaxLocationsByPostalCode(zipCode);

            status.value.tax = 'loaded';
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            status.value.tax = 'error';
        }
    };

    return {
        status,
        aprDetails,
        enhancements,
        fees,
        taxRate,
        installedOptions,
        financingChoices,
        financingChoice,
        useCustomRate,
        mileageOptions,
        mileage,
        leaseCreditScore,
        leaseDownPayment,
        taxLocations,
        leaseSelectedOption,
        findVehicleChoices,
        findVehicleChoice,
        tradeInValue,
        customerDataKey,
        customerData,
        showCustomerModal,
        customerCallback,
        currentLeadArkona,
        vehicleVin,
        vehicleMileage,
        vehicleType,
        vehicleYear,
        vehicleMake,
        vehicleModel,
        discountedPriceValue,
        loanAmount,
        monthlyPayment,
        currentAprDetails,
        termLengths,
        allTermLengths,
        foundAprRate,
        apr,
        scoreToTermMap,
        creditScores,
        startingCreditScore,
        startingTerm,
        startingDownPayment,
        maxDownPayment,
        currentCreditScore,
        currentTermLength,
        currentDownPayment,
        sortedEnhancements,
        selectedEnhancements,
        totalEnhancements,
        totalTaxableEnhancements,
        totalInstalledOptions,
        totalTaxableInstalledOptions,
        groupedFees,
        getGroupedFeesTotalByName,
        ungroupedFees,
        totalTaxableFees,
        taxBasis,
        totalTaxes,
        hasCustomerData,
        hasLeaseSelectedOption,
        combinedCreditScore,
        combinedDownPayment,
        combinedApr,
        combinedTermLength,
        chapmanConnectLoading,
        payoffAmount,
        TOGGLE_ENHANCEMENT,
        TOGGLE_CUSTOM_RATE,
        GET_CUSTOMER_DATA,
        SAVE_CUSTOMER_DATA,
        SET_VEHICLE,
        GET_DATA,
        GET_TAX_LOCATIONS,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useChapmanConnectStore, import.meta.hot)
    );
}
